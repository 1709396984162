import {Box} from "@mui/material";
import {FC, useContext, useEffect} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {LessonPage} from "./LessonPage/LessonPage";
import {QuizPage} from "./QuizPage/QuizPage";
import {WebinarPage} from "./WebinarPage/WebinarPage";
import {ScormPage} from "./ScormPage/ScormPage";
import {PortfolioPage} from "./PortfolioPage/PortfolioPage";
import {PageBaseContext} from "../../contexts/pageBaseContext";

const useStyles = makeStyles<{moduleType?: string}>()((theme, {moduleType}) => ({
	pageBase: {
		height: "100%",
		"& [ class*='background']": moduleType !== "quiz" && {
			backgroundColor: "white",
			[theme.breakpoints.down("sm")]: {
				backgroundColor: "#F2F1F4"
			}
		}
	}
}));

export const ModulePage: FC = () => {
	const {moduleSlug} = useParams();
	const {moduleType} = useParams();
	const [,setContextState] = useContext(PageBaseContext);
	const {classes} = useStyles({moduleType});

	useEffect(() => {
		if(moduleType !== "scorm"){
			setContextState(prev => ({...prev, sidebarEnabled: true, sidebarOpen: true}))
		}
	}, [moduleType])


	if(!moduleSlug) return <></>;

	return (
		<Box className={classes.pageBase} >
			{moduleType === "lesson" && <LessonPage />}
			{moduleType === "quiz" && <QuizPage />}
			{moduleType === "webinar" && <WebinarPage />}
			{moduleType === "portfolio" && <PortfolioPage />}
			{moduleType === "scorm" && <ScormPage />}
		</Box>
	);
}