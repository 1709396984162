import {Box, BoxProps, Typography} from "@mui/material";
import {FC, useEffect} from "react";
import {makeStyles} from "tss-react/mui";

import {QuestionContent} from "./QuestionContent";
import {PortfolioQuestionsFileDropzone} from "../PortfolioQuestionsFileDropzone";
import {QuestionHint} from "./QuestionHint";
import {IPortfolioFileUploadQuestion, IPortfolioFilesUploadQuestionAnswer} from "../../../../../types/portfolio/IPortfolio";
import {IPortfolioAttempt} from "../../../../../types/portfolio/IPortfolioAttempt";


interface IPropsCustom {
	question: IPortfolioFileUploadQuestion,
	answer?: IPortfolioFilesUploadQuestionAnswer,
	onChange: (answer: IPortfolioFilesUploadQuestionAnswer) => void,
	onValidate?: (valid: boolean) => void,
	isError?: boolean,
	onAssessment?: (approved?: boolean) => void,
	readOnly: boolean,
	attempt?: IPortfolioAttempt,
	auditable?: "read" | "submit"
}

export type IProps = Omit<BoxProps, "onChange"> & IPropsCustom;

const useStyles = makeStyles()((theme) => ({
	portfolioFilesUploadQuestion: {
	},
	title: {
		marginTop: "15px"
	},
	optionContainer: {
		display: "grid",
		gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
		justifyContent: "space-between",
		columnGap: "30px",
		rowGap: "10px",
		[theme.breakpoints.down("sm")]: {
			gridTemplateColumns: "minmax(0, 1fr)"
		}
	},
	errorMsg: {
		margin: "10px 0",
		color: "red",
		height: "10px"
	},
	description: {

	}
}));

export const PortfolioFilesUploadQuestion: FC<IProps> = ({className, question, answer, onChange, attempt, onValidate, isError, readOnly, auditable, onAssessment, ...props}) => {
	const {classes, cx} = useStyles();

	useEffect(() => {
		onValidate?.((answer?.files?.length ?? 0) > 0)
	}, [answer]);

	return (
		<Box
			className={cx(classes.portfolioFilesUploadQuestion, className)}
			{...props}
		>
			<Typography className={classes.title} variant="h3">{question.title}</Typography>
			{question.content && <QuestionContent question={question} />}
			<PortfolioQuestionsFileDropzone onAssessment={onAssessment} attempt={attempt} auditable={auditable} question={question} answer={answer} readOnly={readOnly} onChange={onChange} />
			{isError &&
				<Typography className={classes.errorMsg}>{isError && "Please upload at least one file"}</Typography>
			}
			<QuestionHint question={question} />
		</Box>
	);
};