import {FC, useContext, useEffect, useState} from "react"
import {IPortfolioAnswer, IPortfolioQuestion} from "../../../../types/portfolio/IPortfolio";
import {AuthContext, IconContext, useApi} from "@plumeuk/shapeshift-identity";
import {Box, Button, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {IPortfolioAttempt} from "../../../../types/portfolio/IPortfolioAttempt";
import {IPortfolioAnswerAudit} from "../../../../types/portfolio/IPortfolioAnswerAuditRequest";
import {palette} from "../../../../constants";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {InputText} from "@plumeuk/shapeshift-common/inputText";
import {IPortfolioAnswerAssessment, IPortfolioAnswerAssessmentRequest} from "../../../../types/portfolio/IPortfolioAnswerAssessmentRequest";

interface IProps {
	answer?: IPortfolioAnswer,
	readOnly: boolean,
	auditable?: "read" | "submit",
	asssessable?: boolean,
	attempt?: IPortfolioAttempt,
	file?: {id?: number, name?: string},
	question: IPortfolioQuestion,
	onAssessment: (assessment: IPortfolioAnswerAssessment) => unknown
}

const useStyles = makeStyles()((theme) => ({
	feedbackActionContainer: {
		display: "flex",
		gap: theme.spacing(2),
		"& button": {
			height: "fit-content",
			minWidth: "200px",
			display: "flex",
			gap: "5px",
			"&:nth-of-type(1) svg": {
				color: theme.palette.error.main
			},
			"&:nth-of-type(2) svg": {
				color: theme.palette.success.main
			}
		}
	},
	downloadIcon: {
		"path": {
			fill: palette.grey
		}
	}
}));



export const PortfolioQuestionsFileAssessment: FC<IProps> = ({question, attempt, file, answer, readOnly, onAssessment, auditable}) => {
	const {classes, cx} = useStyles();
	const {user} = useContext(AuthContext)
	const [assessment, setAssessment] = useState<Partial<IPortfolioAnswerAssessmentRequest>>();
	const [postAssessmentResponse, putAssessment] = useApi<IPortfolioAnswerAssessment, IPortfolioAnswerAssessmentRequest>();
	const [getAssessmentResponse, getAssessment] = useApi<IPortfolioAnswerAudit>();

	useEffect(() => {
		if(attempt?.id && file?.id && question ){
			getAssessment({
				url: "/api/portfolioAnswerAssessment",
				data: {
					fileId: file.id,
					questionType: question.type,
					attemptId: attempt.id,
					questionId: question.id
				}})
		}
		setAssessment({approve: undefined, comment: ""})
	}, [file?.id, question])

	useEffect(() => {
		if(getAssessmentResponse.data){
			setAssessment({comment: getAssessmentResponse.data.comment, approve: getAssessmentResponse.data.approved})
			onAssessment(getAssessmentResponse.data)
		}
	}, [getAssessmentResponse])

	useEffect(() => {
		if(postAssessmentResponse.data && file?.id){
			onAssessment(postAssessmentResponse.data)
		}
	}, [postAssessmentResponse])

	useEffect(() => {
		if(assessment?.approve !== undefined && attempt?.id && file?.id ){
			if(assessment.approve === getAssessmentResponse.data?.approved && assessment.comment === getAssessmentResponse.data.comment)
				return;

			putAssessment({
				method: "PUT",
				url: "/api/portfolioAnswerAssessment",
				data: {
					approve: assessment.approve,
					attemptId: attempt.id,
					fileId: file?.id,
					questionId: question.id,
					questionType: question.type,
					comment: assessment.comment
				}
			})
		}
	}, [assessment]);


	if((!user?.isAssessor && (!assessment || assessment?.approve === undefined))) return <></>
	return (
		<Box sx={{display: "flex", gap: "20px", flexDirection: "column", margin: "24px 0 14px 0", width: "100%", flexGrow: 2}}>
			{(user?.isAssessor && attempt?.status === "awaiting-review") ? <>
				<Box className={classes.feedbackActionContainer}>
					<Button sx={assessment?.approve ? {opacity: 0.5} : {}} color="error" onClick={() => (setAssessment(prev => ({...prev, approve: false})))}>
						<CloseIcon />
						<Typography>Reject</Typography>
					</Button>
					<Button sx={assessment?.approve === false ? {opacity: 0.5} : {}} onClick={() => (setAssessment(prev => ({...prev, approve: true})))}>
						<DoneIcon />
						<Typography>Approve</Typography>
					</Button>
				</Box>
				<InputText multiline sx={{height: "100%"}} containerProps={{sx:{flexGrow: 3}}} placeholder="feedback" value={assessment?.comment ?? ""} onChange={e => setAssessment(prev => ({...prev, comment: e.target.value ?? ""}))}/>
			</>
				: <>
					<Box display={"flex"} gap={1}>
						{assessment?.approve === false && <CloseIcon />}
						{assessment?.approve && <DoneIcon />}
						<Typography>{assessment?.approve === undefined ? "Not yet assessed" : assessment?.approve ? "Approved" : "Rejected"}</Typography>
					</Box>
					<Typography>Feedback: {assessment?.comment ?? "none"}</Typography>
				</>}
		</Box>
	);
}