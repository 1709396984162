import {FC} from "react";
import {Grid, GridProps, Typography} from "@mui/material";
import {palette} from "../../../constants";
import {makeStyles} from "tss-react/mui";
import {IPortfolioAttemptStatus} from "../../../types/portfolio/IPortfolioAttempt";

const useStyles = makeStyles()((theme) => ({
	portfolioReviewStatusBanner: {
		padding: "32px 40px 32px 32px",
		background: palette.pastelBlue,
		color: palette.darkBlue,
		borderRadius: "10px"
	},
	portfolioGridTitle: {
		marginTop: 0,
		marginBottom: "20px",
		[theme.breakpoints.down("md")]: {
			marginTop: "20px"
		}
	},
	portfolioGridDescription: {
		opacity: "0.7"
	},
	portfolioGridItem: {
		paddingTop: 0,
		margin: 0,
		display: "flex",
		alignContent: "center",
		flexFlow: "wrap",
		alignItems: "center"
	}
}));

interface IPropsCustom {
	status: IPortfolioAttemptStatus
}

export type IProps = GridProps & IPropsCustom;

export const PortfolioReviewStatusBanner: FC<IProps> = ({className, status}) => {
	const {classes, cx} = useStyles();

	const config: { [key in IPortfolioAttemptStatus]: { title: string, text: string, image: string } } = {
		"awaiting-review": {
			title: "Awaiting Review",
			text: "Your portfolio has been submitted and is awaiting review by an Assessor. You will be notified once the review is completed, so you can proceed with the next steps in this course.",
			image: "/portfolio-awaiting-review.png"
		},
		"approved": {
			title: "Portfolio Approved",
			text: "This portfolio has been marked as approved, completing the module for the learner and allowing them to progress",
			image: "/portfolio-approved.png"
		},
		"rejected": {
			title: "Portfolio Rejected",
			text: "This portfolio has been marked as rejected and pushed back to the learner for amendments. Please check the message log for details.",
			image: "/portfolio-rejected.png"
		}
	}
	return (
		<>
			{config[status] && (
				<Grid className={cx(classes.portfolioReviewStatusBanner, className)} container>
					<Grid className={classes.portfolioGridItem} item xs={12} md={3}>
						<img alt="Portfolio Status" src={config[status].image} />
					</Grid>
					<Grid className={classes.portfolioGridItem} item xs={12} md={9}>
						<Typography variant="h4" className={classes.portfolioGridTitle}>{config[status].title}</Typography>
						<Typography className={classes.portfolioGridDescription}>{config[status].text}</Typography>
					</Grid>
				</Grid>
			)}
		</>
	)
}